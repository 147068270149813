import React from 'react';
import ContactForm from './ContactForm';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { colors } from '../../styles/colors';


const CustomContactForm = styled(ContactForm)`
`;

const CustomContainer = styled(Container)`
  background: ${colors.orangishGrey};
  padding: 90px 0;
  
  ${CustomContactForm} {
    grid-column: 2 / span 8;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    padding: 120px 0 160px;
    
    ${CustomContactForm} {
      grid-column: 3 / span 6;
    }
  }
`;


const ContactFormSection = (props) => (
  <CustomContainer id={'contact-form'}>
    <CustomContactForm pageData={props.pageData}/>
  </CustomContainer>
);

export default ContactFormSection;