import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { rtypeN21bu, rtypeN18r, rtypeT21sb, rtypeT38sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';


const ContactItemBrief = styled.div`
  ${rtypeN18r};
`;

const ContactItemTitle = styled.div`
  ${rtypeT21sb};
`;

const ContactItem = styled.li`
  word-break: break-word;
`;

const ContactItems = styled.ul`

`;

const Map = styled.div`
  border-radius: 6px;
  overflow: hidden;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const Subtitle = styled.div`
  ${rtypeN21bu};
`;

const Title = styled.div`
  ${rtypeT38sb};
`;

const CustomContainer = styled(Container)`
  padding-top: 90px;
  
  ${Title} {
    grid-column: 2 / span 8;
    margin-bottom: 42px;
  }
  
  ${Subtitle} {
    grid-column: 2 / span 8;
    padding-bottom: 26px;
    border-bottom: 1px solid ${colors.orange};
    margin-bottom: 30px;
  }
  
  ${ContactItems} {
    grid-column: 2 / span 8;
    margin-bottom: 60px;
    
    ${ContactItem} {
      display: grid;
      grid-template-columns: minmax(120px, auto) 1fr;
      grid-column-gap: 12px;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  ${Map} {
    grid-column: 1 / span 10;
    height: 215px;
  }


  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: auto auto auto 190px;
    padding-top: 120px;
    ${Title} {
      margin-bottom: 54px;
      grid-column: 2 / span 3;
      grid-row: 1;
    }
    
    ${Subtitle} {
      padding-bottom: 30px;
      grid-column: 2 / span 3;
      grid-row: 2;
    }
    
    ${ContactItems} {
      margin-top: 30px;
      grid-column: 2 / span 3;
      grid-row: 3 / span 1;
      ${ContactItem} {
        display: grid;
        grid-template-columns: minmax(140px, auto) 1fr;
        grid-column-gap: 30px;
        
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
    ${Map} {
      grid-column: 6 / span 5;
      grid-row: 3 / span 2;
      height: auto;
    }
  }
`;


const ContactInfoSection = (props) => {
  const pageData = props.pageData;

  return (
    <CustomContainer>
      <Title>{pageData.contact_info_title}</Title>
      <Subtitle>{pageData.contact_info_subtitle}</Subtitle>
      <ContactItems>
        {pageData.contact_items.map(item => (
          <ContactItem key={item.title}>
            <ContactItemTitle>{item.title}</ContactItemTitle>
            <ContactItemBrief dangerouslySetInnerHTML={{__html: item.brief.html}}/>
          </ContactItem>
        ))}
      </ContactItems>
      <Map dangerouslySetInnerHTML={{__html: pageData.map}}/>
    </CustomContainer>
  );
};

export default ContactInfoSection;