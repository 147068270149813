import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { colors } from '../../styles/colors';
import { rtypeN16r, rtypeN21bu } from '../../styles/typography/presets';
import Footnote from '../shared/Footnote';
import RichText from '../shared/RichText';


const Brief = styled(RichText)`
  ${rtypeN16r};
  p {
    margin-bottom: 1.5em;
  }
`;

const Title = styled.div`
  ${rtypeN21bu};
`;

const CustomFootnote = styled(Footnote)`

`;

const CustomContainer = styled(Container)`
  background: linear-gradient(to top right, ${colors.purple}, ${colors.darkBlue});
  color: ${colors.white};
  
  padding-top: 60px;
  
  ${Title} {
    grid-column: 2 / span 8;
    margin-bottom: 30px;
    padding-bottom: 26px;
    border-bottom: 1px solid ${colors.white};
  }
  
  ${Brief} {
    grid-column: 2 / span 8;
    margin-bottom: 120px;
    
    a {
      color: inherit;
    }
  }
  
  ${CustomFootnote} {
    grid-column: 1 / span 9;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    margin-top: -100px;
    padding-top: 120px;
    ${Title} {
      margin-bottom: 30px;
      padding-bottom: 30px;
      grid-column: 2 / span 3;
    }
    ${Brief} {
      grid-column: 2 / span 3;
      margin-bottom: 120px;
    }
    
    ${CustomFootnote} {
      grid-column: 1 / span 10;
    }
  }
`;


const ComplaintsSection = ({pageData, ...props}) => (
  <CustomContainer>
    <Title>{pageData.complaints_title}</Title>
    <Brief content={pageData.complaints_brief.html}/>
    <CustomFootnote
      message={pageData.complaints_footnote}
      buttonText={'Speak with us'}
      to={'/contact/#contact-form'}
      />
  </CustomContainer>
);

export default ComplaintsSection;
