import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { PurpleOutlineButton } from '../buttons';
import { colors } from '../../styles/colors';
import { breakpoints } from '../../styles/layout';
import { rtypeN18r, rtypeT21sb, typeN16r, typeN21r, typeT16sb, typeT21sb } from '../../styles/typography/presets';
import AutoResizeSelect from '../shared/AutoResizeSelect';
import { webhooks } from '../../webhooks';
import { fonts } from '../../styles/typography';

const Title = styled.div`
  ${typeT16sb};
`;

const InlineField = styled(Field)`
  ${rtypeN18r};
  line-height: 30px;
  display: inline;
  color: ${colors.orange};
  cursor: pointer;
  background: none;
  -webkit-appearance: none;
  border: none;
  border-bottom: 1px solid ${colors.orange};
  border-radius: 0;
  padding: 0;
  margin: 0;
`;

const InlineFieldWrapper = styled.div`
  position: relative;
  display: inline;
  color: ${colors.orange};
  ${InlineField} {
    padding-right: 1em;
  }
  &:after {
    ${rtypeN18r};
    pointer-events: none;
    content: '↓';
    font-family: ${fonts.national.family};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
`;

const Brief = styled.div`
  ${typeN16r};
`;

const Header = styled.div`
  background: linear-gradient(to top right, ${colors.darkBlue}, ${colors.purple});
  color: ${colors.white};
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const Salutation = styled.div`
  ${typeT16sb};
`;

const MessageField = styled(Field)`
  ${typeN16r};
  line-height: 40px;
  border: none;
  width: 100%;
  background: linear-gradient(
    transparent, transparent 38px, 
    ${colors.grey} 38px, ${colors.grey} 40px,
    transparent 40px
  );
  background-size: 100% 40px;
  outline: none;
  resize: none;
`;

const ClosingAddress = styled.div`
  ${typeT16sb};
`;

const ContactField = styled(Field)`
  ${typeN16r};
  border: none;
`;

const Closing = styled.div`
  ${ClosingAddress} {
    margin-bottom: 12px;
  }
  ${ContactField} {
    margin-bottom: 6px;
    display: block;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SendButton = styled(PurpleOutlineButton)`
  &:after {
    content: ' →';
    font-family: ${fonts.national.family};
  }
`;

const SuccessMessage = styled.div`
  ${rtypeT21sb};
  color: ${colors.pink};
`;

const Body = styled.div`
  padding: 42px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: ${colors.white};
  
  ${Salutation} {
    margin-bottom: 27px;
  }
  
  ${MessageField} {
    margin-bottom: 33px;
  }
  
  ${Closing} {
    margin-bottom: 40px;
  }
  
  ${SuccessMessage} {
    margin-top: 30px;
  }

  @media(min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    align-items: flex-start;
    
    ${Salutation} {
      ${typeT21sb};
      grid-row: 1 / span 1;
      grid-column: 1 / span 2;
      margin-bottom: 42px;
    }
    
    ${MessageField} {
      grid-row: 2 / span 1;
      grid-column: 1 / span 2;
      margin-bottom: 60px;
      ${typeN21r};
      line-height: 48px;
      background: linear-gradient(
        transparent, transparent 46px, 
        ${colors.grey} 46px, ${colors.grey} 48px,
        transparent 48px
      );
      background-size: 100% 48px;
    }
    
    ${Closing} {
      margin-bottom: 0;
      grid-row: 3 / span 1;
      grid-column: 1 / span 1;
      ${ClosingAddress} {
        ${typeT21sb};
      }
      ${ContactField} {
        ${typeN21r};
      }
    }
    
    ${SendButton} {
      grid-row: 3 / span 1;
      grid-column: 2 / span 1;
      justify-self: flex-end;
      align-self: center;
      width: 137px;
    }
    
    ${SuccessMessage} {
      grid-row: 4;
      grid-column: 1 / span 2;
      margin-top: 60px;
      max-width: 420px;
      justify-self: center;
      text-align: center;
    }
  }
`;

const CustomContainer = styled.div`
  ${Header} {
    padding: 60px 15px;
    
    ${Title} {
      margin-bottom: 40px;
    }
  }
  
  @media(min-width: ${breakpoints.lg}) {
    ${Header} {
      padding: 90px 160px;
      
      ${Title} {
        ${typeT21sb};
      }
      
      ${Brief} {
        ${typeN21r};
      }
      
      ${InlineFieldWrapper} {
        &:after,
        ${InlineField} {
          line-height: 38px;
        }
      }
    }
    ${Body} {
      padding: 90px 160px;
    }
  }
`;


class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
  }

  handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    return fetch(webhooks.contactForm, {
      method: 'POST',
      body: JSON.stringify(values),
    }).then(response => {
      actions.setSubmitting(false);
      this.setState({
        success: true,
      });
    });
  };

  render() {
    const pageData = this.props.pageData;

    const entityChoices = pageData.entity_choices.map(choice => choice.text);
    const actionChoices = pageData.action_choices.map(choice => choice.text);
    const locationChoices = pageData.location_choices.map(choice => choice.text);
    const programsChoices = pageData.programs_choices.map(choice => choice.text);

    return (
      <CustomContainer className={this.props.className}>
        <Formik
          initialValues={{
            entity: entityChoices[0],
            action: actionChoices[0],
            programs: programsChoices[0],
            message: '',
            name: '',
            email: '',
            phone: '',
          }}
          validationSchema={Yup.object().shape({
            message: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            email: Yup.string().email().required('Required'),
            phone: Yup.string(),
          })}
          onSubmit={(values, actions) => {
            this.handleSubmit(values, actions);
          }}
        >
          {({ isValid }) => (
            <Form>
              <Header>
                <Title>{pageData.form_title}</Title>
                <Brief>
                  <span>I am </span>
                  <InlineFieldWrapper>
                    <InlineField name={'entity'} component={AutoResizeSelect}>
                      {entityChoices.map((choice, index) => (
                        <option key={index} value={choice}>{choice}</option>
                      ))}
                    </InlineField>
                  </InlineFieldWrapper>
                  <span> that is interested in </span>
                  <InlineFieldWrapper>
                    <InlineField name={'action'} component={AutoResizeSelect}>
                      {actionChoices.map((choice, index) => (
                        <option key={index} value={choice}>{choice}</option>
                      ))}
                    </InlineField>
                  </InlineFieldWrapper>
                  <span>.<br/>I </span>
                  <InlineFieldWrapper>
                    <InlineField name={'programs'} component={AutoResizeSelect}>
                      {programsChoices.map((choice, index) => (
                        <option key={index} value={choice}>{choice}</option>
                      ))}
                    </InlineField>
                  </InlineFieldWrapper>
                  <span> community initiatives and programs.</span>
                </Brief>
              </Header>
              <Body>
              <Salutation>Dear Korus Connect,</Salutation>
              <MessageField
                placeholder={'Write your message...*'}
                rows={5}
                component={'textarea'}
                name={'message'}/>
              <Closing>
                <ClosingAddress>Yours sincerely,</ClosingAddress>
                <ContactField
                  name={'name'}
                  placeholder={'Your name*'}/>
                <ContactField
                  name={'email'}
                  placeholder={'Email address*'}
                  type={'email'}/>
                <ContactField
                  name={'phone'}
                  placeholder={'Contact number'}
                  type={'tel'}/>
              </Closing>
              <SendButton disabled={!isValid} type={'submit'}>Send</SendButton>
              {this.state.success && (
                <SuccessMessage>Thanks for your message! We'll speak with you soon.</SuccessMessage>
              )}
              </Body>
            </Form>
          )}
        </Formik>
      </CustomContainer>
    );
  }
}

export default ContactForm;