import React from 'react';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import ContactFormSection from '../components/contact/ContactFormSection';
import ContactInfoSection from '../components/contact/ContactInfoSection';
import ComplaintsSection from '../components/contact/ComplaintsSection';
import Hero from '../components/shared/Hero';
import styled from 'styled-components';
import { colors } from '../styles/colors';


const ContactHero = styled(Hero)`
  background: ${colors.orangishGrey};
`;

class ContactPage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page("Contact", {
      title: `Contact | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const props = this.props;
    const pageData = props.data.prismicContactPage.data;

    return (
      <Layout location={props.location} reverseLogo={true}>
        <Helmet>
          {titleTags(`Contact | ${props.data.prismicSite.data.name}`)}
          {descriptionTags(pageData.meta_description)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <ContactHero
          title={pageData.hero_title}
          message={pageData.hero_message}
          brief={pageData.hero_brief.html}
          image={pageData.hero_image}
        />
        <ContactFormSection pageData={pageData}/>
        <ContactInfoSection pageData={pageData}/>
        <ComplaintsSection pageData={pageData}/>
      </Layout>
    );
  }
}

export default ContactPage;


export const query = graphql`
  query ContactPageQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicContactPage {
      data {
        hero_title
        hero_message
        hero_brief {
          html
        }
        hero_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              
            }
          }
        }
        form_title
        entity_choices {
          text
        }
        action_choices {
          text
        }
        location_choices {
          text
        }
        programs_choices {
          text
        }
        contact_info_title
        contact_info_subtitle
        contact_items {
          title
          brief {
            html
            text
          }
        }
        map
        complaints_title
        complaints_brief {
          html
          text
        }
        complaints_footnote
      }
    }
  }
`;