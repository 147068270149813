import React from 'react';


class AutoResizeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      width: 1,
    };
    this.actualRef = React.createRef();
    this.phantomRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      value: this.props.children[0].props.value,
    });
    setTimeout(this.updateWidth, 100);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.value !== this.state.value) {
      this.updateWidth();
    }
  }

  updateWidth = () => {
    const node = this.phantomRef.current;
    if (!node) return;
    this.setState({
      width: node.clientWidth,
    });
  };

  onChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  render() {
    return (
      <span style={{position: 'relative'}}>
        <select
          style={{ width: this.state.width }}
          ref={this.actualRef}
          onChange={this.onChange}
          className={this.props.className}>
          {this.props.children}
        </select>
        <select
          style={{position: 'absolute', visibility: 'hidden', top: 0, left: 0}}
          className={this.props.className}
          ref={this.phantomRef}>
          <option>{this.state.value}</option>
        </select>
      </span>
    );
  }
}

export default AutoResizeSelect;